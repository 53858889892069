.redesignActive {
  .pointer {
    cursor: pointer;
  }

  .underline {
    text-decoration: none;
  }

  .underline:hover {
    text-decoration: underline;
  }
}