// -------------Navigation Menu----------------------------
.ant-tooltip-inner > svg {
  display: none !important;
}

.ant-layout-sider {
  height: 100%;
}

// -------------Navigation Menu----------------------------
