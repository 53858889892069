//-------------Calendar----------------------------
.redesignActive {
  .ant-picker-dropdown-placement-bottomRight > .ant-picker-panel-container {
    width: 167px;
    padding-top: 0px;
    border-radius: 0px;
    .ant-picker-panel > .ant-picker-footer {
      .ant-picker-ranges {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: row;
        background: white;
        min-width: 167px;
        padding-top: 8px;
        .ant-picker-now > .ant-picker-now-btn {
          color: @neon-green;
        }
      }
    }
  }

  .ant-picker-dropdown-placement-topRight {
    .ant-picker-panel-container {
      width: auto;
      padding-top: 0px;
      border-radius: 0px;

      .ant-picker-panel > .ant-picker-footer {
        .ant-picker-ranges {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-direction: row;
          background: white;
          min-width: 167px;
          padding-top: 8px;
          box-shadow: 0px 18px 18px rgba(0, 0, 0, 0.13);
          .ant-picker-now > .ant-picker-now-btn {
            color: @neon-green;
          }
        }
      }
    }
  }

  .ant-picker-dropdown-placement-bottomLeft > .ant-picker-panel-container {
    width: auto;

    .ant-picker-panel > .ant-picker-date-panel {
      border-bottom: none;
    }

    .ant-picker-panel > .ant-picker-footer {
      display: none;
      width: min-content;
      min-width: 100%;
      line-height: 38px;
      text-align: center;
      border-bottom: 1px solid transparent;
    }
  }

  .ant-picker-dropdown-placement-topLeft > .ant-picker-panel-container {
    width: auto;
  }

  .ant-picker-panel-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    width: 200px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    min-height: 100px;
    max-height: 340px;
    margin-left: 0 !important;
  }

  .ant-picker-panel-container.custom-preset-active {
    min-width: 750px;
    min-height: 350px;
  }

  .ant-picker-panel-container.custom-preset-active > .ant-picker-panels,
  .ant-picker-panel-container.custom-preset-active .ant-picker-footer-extra {
    visibility: visible;
    opacity: 1;
  }

  .ant-picker-ranges {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    justify-content: flex-start;
    align-items: baseline;
    min-width: 200px;
    padding-top: 25px;
  }

  .ant-picker-date-panel {
    border-bottom: 1px solid #dedede;
  }

  .ant-picker-footer-extra {
    position: absolute;
    right: 0;
    bottom: 25px;
    left: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    border: 0;
    visibility: hidden;
    opacity: 0;
  }

  .ant-picker-panels {
    border-left: 1px solid #dedede;
    visibility: hidden;
    opacity: 0;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    font-family: 'AkkuratLLWeb-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #242e2c;
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view) .ant-picker-cell-inner {
    opacity: 0.3;
  }

  .ant-picker-content thead tr th {
    font-family: 'AkkuratLLWeb-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }

  .ant-picker-range {
    border-radius: 50px;
    min-width: 242px;
  }

  .ant-picker-range.rangePicker.fr .ant-picker-input > input {
    margin-left: 15px;
  }

  .ant-picker:hover {
    border-color: @neon-green;
  }

  .ant-picker.ant-picker-range:active,
  .ant-picker.ant-picker-range:target,
  .ant-picker-focused {
    box-shadow: none;
    border-color: @neon-green !important;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: unset;
  }

  .ant-picker-preset .ant-tag {
    font-family: 'AkkuratLLWeb-Regular';
    font-weight: 400;
    font-style: normal;
    color: #242e2c;
    background: unset;
    border: unset;
    font-size: 13px;
    line-height: 14px;
    width: 100%;
  }

  .ant-picker-preset:not(.active-preset):hover .ant-checkbox .ant-checkbox-inner,
  .ant-picker-preset:not(.active-preset):hover .ant-checkbox-wrapper:hover {
    border-color: #c1c3d0;
  }

  .ant-picker-preset:not(.active-preset):hover .ant-checkbox .ant-checkbox-inner {
    background-color: #f1f1f1;
  }

  .ant-picker-ranges > li {
    width: 100%;
    margin-bottom: 5px;
  }

  span[ant-click-animating-without-extra-node='true'] ~ li {
    background-color: #242e2c;
  }

  span[ant-click-animating-without-extra-node='true'] {
    color: #54dec7;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: @neon-green;
    top: 100%;
    left: 50%;
    transform: translate(-25%, -25%);
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    display: none;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
    .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
    .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    border-radius: 50%;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: @neon-green;
    color: #242e2c;
    border-radius: 50%;
  }

  .ant-picker-cell-inner {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #54dec7;
    opacity: 0.1;
  }

  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:last-of-type:before,
  td.ant-picker-cell.ant-picker-cell-end.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #54dec7;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
  }

  td.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range:first-of-type:before,
  td.ant-picker-cell.ant-picker-cell-start.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #54dec7;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
  }

  .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: unset;
  }

  .ant-picker-month-btn,
  .ant-picker-year-btn,
  .ant-picker-month-btn:hover,
  .ant-picker-year-btn:hover {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #242e2c;
  }

  input[placeholder='Start date'],
  input[placeholder='End date'] {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #242e2c;
    text-align: center;
    cursor: pointer;
  }

  .ant-picker.ant-picker-range {
    padding: 10px 12px 10px 25px;
    position: relative;
    cursor: pointer;
    box-shadow: none;
  }

  .ant-picker.ant-picker-range.rangePicker.ant-picker-focused {
    border-color: @neon-green;
    box-shadow: none;
  }

  .ant-picker.ant-picker-range:hover {
    background-color: #fafafa;
  }

  .ant-picker.ant-picker-range::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.90625 6.42855H13.7188M3.43304 1.5V2.78586M12.0312 1.5V2.78571M14.5625 4.78571L14.5625 14.5C14.5625 15.6046 13.6671 16.5 12.5625 16.5H3.0625C1.95793 16.5 1.0625 15.6046 1.0625 14.5V4.78571C1.0625 3.68114 1.95793 2.78571 3.0625 2.78571H12.5625C13.6671 2.78571 14.5625 3.68114 14.5625 4.78571Z' stroke='%237D838A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    position: absolute;
    top: 9px;
    left: 12px;
    width: 15px;
    height: 15px;
  }

  .ant-picker.ant-picker-range::after {
    content: url("data:image/svg+xml, %3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg' className='arrow-icon' %3E%3Cpath d='M5.657 4.24296L1.414 8.48596L0 7.07096L2.829 4.24296L0 1.41496L1.414 -3.8147e-05L5.657 4.24296Z' fill='%2309121F' /%3E%3C/svg%3E");
    position: absolute;
    top: 9px;
    right: 10px;
    transform: rotate(90deg);
    transition: all 500ms ease;
  }

  .ant-picker-prev-icon::before {
    content: url("data:image/svg+xml, %3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg' className='arrow-icon' %3E%3Cpath d='M5.657 4.24296L1.414 8.48596L0 7.07096L2.829 4.24296L0 1.41496L1.414 -3.8147e-05L5.657 4.24296Z' fill='%2309121F' /%3E%3C/svg%3E");
    border: 0;
    width: auto;
    height: auto;
    transform: rotate(180deg);
    top: -15px;
    position: absolute;
  }

  .ant-picker-next-icon::before {
    content: url("data:image/svg+xml, %3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg' className='arrow-icon' %3E%3Cpath d='M5.657 4.24296L1.414 8.48596L0 7.07096L2.829 4.24296L0 1.41496L1.414 -3.8147e-05L5.657 4.24296Z' fill='%2309121F' /%3E%3C/svg%3E");
    border: 0;
    width: auto;
    height: auto;
    top: -15px;
    position: absolute;
    transform: rotate(0deg);
  }

  .ant-picker-header {
    position: relative;
  }

  .ant-picker-header-prev-btn:hover:after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    left: 25px;
    top: 10px;
    border: 1px solid #c4c6c6;
    height: 25;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }

  .ant-picker-header-next-btn:hover::after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    right: 25px;
    top: 9px;
    border: 1px solid #c4c6c6;
    height: 25;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }

  .ant-picker-header-prev-btn:active::after,
  .ant-picker-header-prev-btn:focus::after,
  .ant-picker-header-next-btn:focus::after,
  .ant-picker-header-next-btn:active::after {
    background-color: #dedede;
    opacity: 0.5;
  }

  .ant-picker-super-prev-icon::after,
  .ant-picker-super-prev-icon::before,
  .ant-picker-super-next-icon::after,
  .ant-picker-super-next-icon::before {
    display: none;
  }

  .ant-picker-prev-icon,
  .ant-picker-next-icon {
    transform: unset;
    background-color: transparent;
    border: 0;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-range-separator {
    position: relative;
    width: 0em;
    padding: 0;
  }

  .ant-picker-range-separator span[role='img'] {
    display: none;
  }

  .ant-picker-range-separator::before {
    content: '';
    width: 5px;
    border-bottom: solid 2px #000;
    position: absolute;
    left: -2.5px;
    top: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #242e2c;
  }

  li.ant-picker-preset.active-preset,
  li.ant-picker-preset.active-preset:hover span.ant-tag,
  li.ant-picker-preset.active-preset:hover {
    // background: #242e2c;
  }

  li.ant-picker-preset:not(.active-preset):hover + .ant-checkbox-inner {
    // background-color: #f1f1f1;
  }

  li.ant-picker-preset {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }

  .active-preset span.ant-tag {
    // color: #54dec7;
  }

  .ant-btn.ant-btn-ghost {
  }

  .ant-picker.ant-picker-range.ant-picker-focused::after {
    transform: rotate(-90deg);
    transition: all 500ms ease;
  }

  .open.ant-picker.ant-picker-range::after {
    // transform: rotate(-90deg);
    // transition: all 500ms ease;
  }

  .ant-picker-input > input {
    height: auto;
    padding-left: 5px;
    font-size: 13px;
    font-family: 'AkkuratLLWeb-Regular';
  }
}
//-------------Calendar----------------------------
