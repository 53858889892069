//-------------Table---------------------
.redesignActive {
  .ant-table-thead th.ant-table-column-sort,
  .ant-table-thead > tr > th {
    .paragraph-03-bold;
  }

  .ant-table-tbody .ant-table-cell {
    .paragraph-02-regular;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #dedede;
    background: @white;
    // text-align: center;
  }

  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    // background: transparent;
    // making the background transparent will prevent each hovered row to be the light gray. This also help with the white stroke around the badges. 
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border: 0px;
  }

  .ant-table-column-title {
    font-family: 'AkkuratLLWeb-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15.6px;
    color: #000000;
  }

  .ant-table-thead> tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
    width: none; // <-- add 1px to bring back the column divider between the table's header
  }
}
//-------------Table---------------------
