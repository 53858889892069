.redesignActive {
  .ant-pagination-item-active {
    background-color: #30d7ba;
    border-radius: 20px;
    border: none;
  }

  .ant-pagination-item {
    border-radius: 20px;
  }
  .ant-pagination-item:hover {
    border-color: @neon-green;
  }

  .ant-pagination-item a {
    line-height: unset !important;
    .paragraph-01-regular;
    color: @soft-black;
    font-weight: 700;
  }

  .ant-pagination-item:hover a {
    color: @soft-black;
  }

  .ant-pagination-item-active:hover a {
    color: black;
  }

  .ant-pagination-item-active a {
    color: black;
  }

  .ant-pagination-disabled .ant-pagination-item-link {
    background-color: #fafafa;
    border: 1px #f1f1f1 solid;
  }

  .ant-pagination-item-link span.anticon.anticon-right {
    display: block;
  }

  .ant-pagination-prev a,
  .ant-pagination-next a {
    color: @soft-black;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    position: relative;
  }

  .ant-pagination-prev::after,
  .ant-pagination-next::after {
    content: url("data:image/svg+xml, %3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 13L7 9L11 5' stroke='%23242E2C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E ");
    position: absolute;
  }

  .ant-pagination-prev::after {
    left: 0px;
    top: 5px;
  }

  .ant-pagination-next::after {
    right: 0px;
    transform: rotate(180deg);
    top: -5px;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    top: 5px;
    color: @soft-black;
    opacity: 1 !important;
  }

  .anticon.anticon-double-left.ant-pagination-item-link-icon,
  .anticon.anticon-double-right.ant-pagination-item-link-icon,
  button.ant-pagination-item-link {
    display: none;
  }
}