//-------------Tags---------------------
.redesignActive {
  .ant-tag {
    color: black;
    border-radius: 17px;
    font-weight: 400;
    padding: 4px 8px;
    border-color: transparent;
    font-family: AkkuratLLWeb-Regular;
    font-size: 13px;
  }
}
//-------------Tags---------------------
