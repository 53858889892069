//-------------Buttons-----------------------------
.redesignActive {
  .ant-btn {
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 5px;
    box-shadow: none;
    height: 38px;
  }

  .ant-btn-general {
    border-radius: 20px;
    text-transform: uppercase;
    text-shadow: none;
  }

  .ant-btn-colors {
    background: @neon-green;
    border: 1px solid @neon-green;
  }

  .ant-btn-ghost {
    border: 1px solid @light-gray;
  }

  .ant-btn-ghost:hover {
    border: 1px solid @dark-gray;
  }

  .ant-btn-ghost:hover {
    color: #242e2c;
  }

  .ant-btn-ghost:active,
  .ant-btn-ghost:focus {
    border: 1px solid @dark-gray;
    background: @cultered;
    color: @black;
  }

  .ant-btn-primary {
    color: @black;
    .ant-btn-colors;
    .ant-btn-general;
  }

  .ant-btn-primary:hover {
    background: @neonGreenHover;
    border: 1px solid @neonGreenHover;
    .ant-btn-general;
  }

  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    .ant-btn-colors;
    color: @black;
    border-radius: 20px;
  }

  .ant-btn-default {
    background-color: @soft-black;
    border-color: @soft-black;
    color: @white;
    padding: 10px 20px;
    border-radius: 35px;
    .ant-btn-general;
  }

  .ant-btn-default:hover {
    background-color: @soft-black-hover;
    border-color: @soft-black-hover;
  }

  .ant-btn-default[disabled],
  .ant-btn-primary[disabled] {
    background-color: @cultered;
    border-color: @cultered;
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-btn-default[disabled]:hover,
  .ant-btn-primary[disabled]:hover {
    background-color: @cultered;
    border-color: @cultered;
  }

  .ant-btn-link {
    font-size: 13px;
    color: @neon-green;
  }
}
//-------------Buttons-----------------------------
