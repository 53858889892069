@import '../../src/fonts/stylesheet.css';
@import './app-header.less';
@import './buttons.less';
@import './colors.less';
@import './cursor.less';
@import './date-picker.less';
@import './element.less';
@import './input.less';
@import './highcharts.less';
@import './location-selector.less';
@import './menu-dropdown.less';
@import './navigation-menu.less';
@import './notification-banner.less';
@import './pagination.less';
@import './select-inputs.less';
@import './table.less';
@import './tags.less';
@import './tabs.less';
@import './tenant-selector.less';
@import './typography.less';

.redesignActive {
  .ant-layout-content {
    background-color: @grayBackground;
    padding: 8px 20px !important;
  }
  .highcharts-container {
    width: 100% !important;
    height: 100% !important;
  }

  // .ant-select-dropdown {
  // height: 93px;
  // overflow: visible;
  // }

  .ant-cascader-menus .ant-cascader-menu:first-of-type {
    height: 70px;
    border-radius: 5px;
  }

  .ant-cascader-menus .ant-cascader-menu:nth-child(2) {
    height: 285px;
    background: white;
    border-radius: 5px;
  }

  li.last-item-selected {
    border-bottom: 1px solid #dedede;
  }

  li.ant-cascader-menu-item.ant-cascader-menu-item-expand.ant-cascader-menu-item-active
    .ant-cascader-menu-item-expand-icon::after {
    transform: rotate(0deg);
    transition: all 500ms ease;
  }

  .rc-virtual-list-holder-inner {
    background-color: #fff;
  }

  .ant-picker-range-wrapper {
    min-height: 340px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: none;
  }
}

@font-variant-base: 'normal';
@font-feature-settings-base: 'normal';

.current-home-dashboard-link svg {
  color: #fff;
}

.current-home-dashboard-link svg.svg-inline--fa.fa-home.fa-w-18.ant-menu-item-icon {
  width: 20px;
  height: 20px;
}

.current-home-dashboard-link.collapsed svg.svg-inline--fa.fa-home.fa-w-18.ant-menu-item-icon {
  margin-left: 10px;
}

.ant-menu-item.ant-menu-item-selected.current-home-dashboard-link.expanded .ant-menu-title-content,
.ant-menu-item.current-home-dashboard-link.expanded:not(.ant-menu-item-selected) .ant-menu-title-content {
  margin-left: 0;
}

.ghg-calcuation-modal .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.redesignActive.peakShavingEvents .ant-picker-panel-container {
  width: auto;
  .menu-2 {
    display: flex;
    justify-content: 'start';
    align-items: center;
  }

  .current-user {
    border-bottom: 1px solid @light-gray;
  }

  .ant-menu-item.menu-2.switch-old-homepage-toggle {
    padding-left: 12px;
  }
}
.ant-menu-submenu li[aria-disabled='true'] .ant-menu-title-content::after {
  content: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.49956 7.379L12.3936 0.484253L13.4548 1.54475L5.49956 9.5L0.726562 4.727L1.78706 3.6665L5.49956 7.379Z' fill='%2330D7BA'/%3E%3C/svg%3E");
  position: absolute;
  right: 12.5px;
  top: 12px;
}

li.ant-menu-item.menu-2.switch-old-homepage-toggle,
li.ant-menu-item.menu-2.logout-optn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
