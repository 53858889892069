/* -------------Select Inputs--------------------- */
.redesignActive {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 41px;
    border: 1px solid #dedede;
    background-color: @white;
  }
  .ant-select-selection-overflow {
    margin-left: 25px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    // border-color: rgba(0, 0, 0, 0.3);
    border-color: @neon-green;
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .ant-select:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):focus-within .ant-select-selector {
    border-color: @neon-green;
    box-shadow: none;
    outline: 0;
    border-right-width: 1px;
    // adding a 'focus-within' maintains the border color like its hovered color when the cursor move out of the selector.
 }

  .ant-select-selection-item,
  .ant-select-multiple .ant-select-selection-item {
    background-color: @neonGreen10;
    border: 0;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    background-color: transparent;
  }

  .ant-select-selection-search-input {
    padding-top: 4px !important;
    border-radius: 0px !important;
  }

  // TODO: get the last selected tenants and add a border-bottom line
  // .rc-virtual-list-holder-inner div.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  //   border-bottom: 1px red solid;
  // }
  // .ant-select-selection-item-remove {
  //   display: none !important;
  // }

  .ant-cascader-checkbox-inner {
    border-radius: 50%;
  }

  .ant-cascader-checkbox-checked::after {
    border: 0;
    border-color: @neon-green;
  }

  .ant-cascader-checkbox:hover .ant-cascader-checkbox-inner {
    border-color: #54dec7;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner,
  .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner {
    background-color: #54dec7;
  }

  .ant-cascader-checkbox-checked .ant-cascader-checkbox-inner::after {
    border-color: @black;
  }

  .ant-cascader-menu-item[aria-checked='true'],
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background: #fff;
  }

  .ant-cascader-checkbox-indeterminate .ant-cascader-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    width: 5.71428571px;
    height: 9.14285714px;
    top: 50%;
    left: 21.5%;
    border-color: #000;
    background-color: @neon-green;
    content: ' ';
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    background-color: @neonGreen10;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: white;
  }

  .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: white;
  }
}
/* -------------Select Inputs--------------------- */