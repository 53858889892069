.custom-message-banner {
    padding: 10px 15px !important;
    border-radius: 5px !important;

    .ant-notification-notice {
        padding: 10px 15px !important;
    }
    .ant-notification-notice-message {
        margin-bottom: 0px !important;
        margin-left: 38px;
    }
    svg {
        margin-bottom: 8px !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @black !important;
        border-color: @black !important;
    }
}

.success {
    background: @neon-green !important;
    
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: @neon-green !important;
    }
}

.warning {
    background: @orangeBase;

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: @orangeBase;
    }
}

.error {
    background: @redBase;

    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: @redBase;
    }
}
