/* -------------Select Inputs--------------------- */
.redesignActive {
    /* Styling for all input elements */
input {
    border-radius: 40px !important;
    height: 38px;
}

.ant-input-number {
    border-radius: 40px !important;
    height: 38px;
}

/* Styling for Ant Design's input elements */
.ant-input {
    -webkit-font-feature-settings: normal!important;
    font-feature-settings: normal!important;
    font-variant: normal!important;
    font-size: 13px;
    font-family: 'AkkuratLLWeb-Regular';
}

/* Hover effect for Ant Design's input elements */
.ant-input:hover {
    border-color: #30d7ba;
}
.ant-input-number:hover {
    border-color: #30d7ba;
}

/* Focus effect for Ant Design's input elements */
.ant-input:focus {
    border-color: #30d7ba;
    box-shadow: none;
}
.ant-input-number-focused {
    border-color: #30d7ba;
    box-shadow: none;
}

/* Styling for the placeholder text of Ant Design's input elements */
.ant-input::placeholder {
    -webkit-font-feature-settings: normal !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
}

  }
  /* -------------Inputs--------------------- */