.download-sub-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.highcharts-exporting-group {
  display: none !important;
}

ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.download-sub-menu {
  background: none;
  position: absolute;
  top: 16px;
  border: none;
  z-index: 1;
  right: 370px;
}
