
.redesignActive {
  .opacity-06 {
    opacity: 0.6;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-and-gap-below {
    .flex-column;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
  }

  .flex-and-space-between-gap-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 221px;
  }

  .flex-right {
    .flex-row;
    justify-content: flex-end;
    align-items: flex-end;
  }
}